.book__date__time__container {
  background: #00b4db;
  padding-bottom: 50px;
}
.book__date__time__header {
  text-align: center;
  padding: 10px;
}
.book__date__time__header > img {
  width: 250px;
}
.book__date__time__panel {
  background: white;
  border-radius: 30px;
  margin: 50px 5%;
}
.book__date__time__left-panel {
  width: 50%;
  text-align: center;
  margin-right: 50px;
}
.book__date__time__right {
  width: 60%;
}
.book__date__time__panel > h1 {
  text-align: center;
  padding: 50px;
  font-size: 40px;
}
.book__date__time__panel-items {
  display: flex;
  font-size: 15px;
  margin: auto 50px;
}

.book__date__time__left-panel > h1 {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  line-height: 25px;
}
.book__date__time__left-panel > img {
  width: 300px;
}

.book__date__time__date-picker {
  font-size: 15px;
}
.book__date__time__date-picker-row > button {
  border: none;
  outline-width: 0px;
  padding: 5px;
  margin: 10px 15px 10px 0px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #7f7f7f;
  font-size: 18px;
  width: 30%;
}
.book__date__time__date-picker-row > button:disabled {
  border: 1px solid gray;
}
.book__date__time__date-picker-selected {
  background: #00b4db !important;
  color: white;
  border: 1px solid #00b4db !important;
}
.book__date__time__time-picker {
  font-size: 15px;
  margin-top: 50px;
}
.book__date__time__time-picker-row > button {
  border: none;
  outline-width: 0px;
  padding: 5px;
  margin: 10px 10px 10px 0px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #7f7f7f;
  font-size: 15px;
  width: 20%;
}
.book__date__time__time-picker-row > button:disabled {
  border: 1px solid #ebebe4;
  cursor: auto;
}

.book__date__time__submit-container {
  margin: 20px auto;
}
.book__date__time__submit-container > button {
  background-color: #5db11d;
  border: none;
  outline-width: 0px;
  padding: 10px;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  width: 30%;
}

@media (max-width: 990px) {
  .book__date__time__panel {
    width: 90%;
    margin: 25px auto;
  }
  .book__date__time__panel > h1 {
    font-size: 25px;
    margin: 0px;
    padding: 20px;
  }
  .book__date__time__left-panel {
    display: none;
  }
  .book__date__time__right {
    width: 100%;
  }
  .book__date__time__panel-items {
    margin: 15px;
  }
  .book__date__time__date-picker {
    text-align: left;
  }
  .book__date__time__date-picker-row > button {
    min-width: 50px;
    width: 130px;
    font-size: 12px;
  }
  .book__date__time__time-picker-row > button {
    min-width: 50px;
    width: 80px;
  }
  .book__date__time__submit-container > button {
    font-size: 15px;
    width: 60%;
  }
}
