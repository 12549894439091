
.main{
    height: 100%;
    width: 100%;
    position:absolute;
    top: 0;
}
.popup{
    height: 412px;
    width: 148%;
    background-color: white;
    position: absolute;
    top: 0%;
    right: -23%;
    border-radius: 1rem;
    padding: 2rem 2rem;
}
.popup-header{
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 15px;
}

.cross-btn{
    padding: 10px;
    cursor: pointer;
}