.select_login {
  background-color: rgba(13, 114, 236, 0.8);
  padding: 20px 100px;
  min-height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
}
.header span {
  font-size: 1.2rem;
  line-height: 27px;
  color: #ffffff;
}
.brand {
  width: 200px;
}
.header_back {
  width: 200px;
  display: flex;
  align-items: center;
}

.select_login_welcome {
  font-size: 3rem;
  font-weight: 600;
  color: #ffffff;
  margin-top: 5rem;
  text-align: center;
}

.hl {
  display: none;
  border-bottom: 2px solid #d3d3d3;
  width: 100%;
}

.vl {
  border-left: 2px solid #d3d3d3;
  height: 304px;
  margin-left: 2rem;
  margin-right: 2rem;
}
.select_login_content {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.dialogbox {
  width: 50%;
  min-width: 400px;
  height: 206px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
}

.dialogbox .imageCol {
  display: flex;
  align-items: center;
}

.dialogbox .loginDialogTextCol {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.dialogbox .loginDialogTextCol .loginLabel {
  margin-top: 70px;
  color: black;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.loginButton {
  cursor: pointer;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  width: 170px;
  height: 49px;
  background: #00f9bd;
  border-radius: 12px;
}

.loginImage {
  width: 140px;
}

.loginLabel {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
@media all and (max-width: 1050px) {
  .select_login_content {
    flex-direction: column;
    height: 500px;
    margin-bottom: 100px;
  }
  .header {
    justify-content: center;
  }
  .header_back {
    display: none;
  }
  .vl {
    display: none;
  }
  .hl {
    display: block;
  }
}
@media all and (max-width: 780px) {
  .select_login {
    padding: 20px 20px;
  }
  .select_login_welcome {
    font-size: 2rem;
  }
  .dialogbox {
    width: 100%;
    min-width: 100%;
  }
}
@media all and (max-width: 550px) {
  .dialogbox {
    height: 170px;
  }
  .loginImage {
    width: 100px;
  }
  .dialogbox .loginDialogTextCol .loginLabel {
    font-size: 19px;
  }
  .dialogbox .loginDialogTextCol .loginLabel {
    margin-top: 20px;
  }
  .loginButton {
    font-size: 15px;
    width: 120px;
    height: 40px;
    background: #00f9bd;
    border-radius: 12px;
  }
  .select_login_welcome {
    font-size: 1.7rem;
  }
}
