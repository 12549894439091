.booking__congratulations__box {
  border: 3px solid #afafaf;
  border-radius: 50px;
  text-align: center;
  padding: 50px 0px;
}

.booking__congratulations__wrapper {
  margin: 10% 12%;
}

.booking__congratulations__box > img {
  margin-top: 40px;
  width: 250px;
  margin-bottom: 0px;
}
.booking__congratulations__box > h1 {
  margin: 0px;
  font-size: 35px;
  font-weight: bolder;
  padding: 0px;
}
.wait_text {
  color: #006d0f;
  font-weight: 600;
  padding-top: 2rem;
  font-size: 1.2rem;
}
.booking__congratulations__wrapper_nopc {
  margin: 5% 10% 0;
}
.booking__congratulations__nopc {
  border: 3px solid #afafaf;
  border-radius: 20px;
  text-align: center;
  padding: 50px 10px;
}
.booking__congratulations__nopc h1 {
  color: #00b2d9;
  font-size: 2rem;
  font-weight: 700;
}
.booking__congratulations__nopc .booking__congratulations__text__nopc {
  font-weight: 500;
  width: 80%;
  margin: 50px auto;
}
.wait_text_nopc {
  font-weight: 600;
  font-size: 1.2rem;
}

@media all and (max-width: 780px) {
  .wait_text {
    line-height: 0.9;
  }
  .wait_text_nopc {
    line-height: 0.6;
  }
  .booking__congratulations__nopc h1 {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .booking__congratulations__nopc p {
    font-size: 0.8rem;
    width: 100%;
    margin: 50px auto;
  }
}

.load_text:after {
  content: " .";
  font-size: 2rem;
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgb(0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: rgb(0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgb(0, 0, 0);
  }
}
.booking__congratulations__footer-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.booking__congratulations__footer-message img {
  width: 50px;
  margin-left: 10px;
}
.booking__congratulations__container {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (max-width: 990px) {
  .booking__congratulations__container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .booking__congratulations__box {
    width: 80%;
    margin: auto;
    border: 2px solid #00b4db;
    border-radius: 40px;
    text-align: center;
    padding: 18px;
    /* margin: 10px auto; */
  }
  .booking__congratulations__box > img {
    margin-top: 40px;
    width: 150px;
    margin-bottom: 0px;
  }
  .booking__congratulations__box > h1 {
    margin: 0px;
    font-size: 20px;
    font-weight: bolder;
    padding: 0px;
  }
  .booking__congratulations__footer-message {
    font-size: 15px;
    display: block;
  }
  .booking__congratulations__footer-message img {
    width: 30px;
  }
}
