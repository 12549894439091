.login__with__otp__form-container {
  border: 3px solid #afafaf;
  border-radius: 20px;
  padding: 50px;
  padding-right: 20px;
  padding-top: 0px;
}
.otp_err_text{
  color: red;
  text-align: center;
  margin-top: 1rem;
}
@media all and (min-width: 1000px) {
  .ReactModal__Content {
    width: 60%;
  }
}

.login__with__otp__form-container {
  background-color: #fff;
}
.close-button-container {
  text-align: right;
  padding: 10px 0px 0px 0px;
}
.close-button-container svg {
  font-size: 2rem;
}
.close-button-container > button {
  background: transparent;
  border: none;
  outline-width: 0px;
  cursor: pointer;
  color: #7f7f7f;
}
.login__with__otp__header {
  font-size: 2rem;
  font-weight: bolder;
  text-align: center;
}
.login__with__otp__subheader {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin: 20px auto;
}
.login__with__otp__input-container {
  text-align: center;
  display: flex;
  justify-content: center;
}
.login__with__otp__input-container > input {
  width: 40px;
  height: 40px;
  padding: 5px;
  border: 2px solid #7f7f7f;
  border-radius: 5px;
  background: #f2f2f2;
  outline-width: 0px;
  font-size: 20px;
  margin: auto 2px;
  text-align: center;
}

.login__with__otp__submit-container {
  margin: 20px auto;
  text-align: center;
}
.login__with__otp__submit-container > button {
  background: #00b4db;
  color: white;
  font-size: 25px;
  font-weight: 700;
  padding: 2px 8px;
  outline-width: 0px;
  border: none;
  border-radius: 8px;
  width: 170px;
  cursor: pointer;
}

@media (max-width: 990px) {
  .login__with__otp__page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  .login__with__otp__form-container {
    border: 3px solid #7c7c7c;
    padding: 10px 5px 10px 10px;
    margin: 10px;
    width: 70%;
  }
  .close-button-container {
    padding: 0;
    padding-right: 10px;
  }
  .login__with__otp__header {
    font-size: 25px;
  }
  .login__with__otp__subheader {
    font-size: 18px;
    line-height: 20px;
  }
  .login__with__otp__input-container > input {
    width: 30px;
    height: 30px;
    font-size: 0.9rem;
  }
}

@media all and (max-width: 440px) {
  .login__with__otp__form-container {
    width: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
