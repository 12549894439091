.wrapper {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00b4db;
  padding-bottom: 100px;
}
.header {
  margin-top: 5%;
  margin-bottom: 5%;
}

.logo {
  width: 200px;
  cursor: pointer;
}
.payment .brand {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.form-control {
  border: 2.0239px solid #7f7f7f;
}
.jumboCard {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  width: 80vw;
  margin-bottom: 5%;
}
.form svg {
  font-size: 28px;
  padding-left: 5px;
}
.jumboCard h1 {
  font-size: 1.5rem;
}
.containerRow {
  display: flex;
  justify-content: space-evenly;
}
.form-group .PhoneInput {
  width: 100%;
}

.cardCol {
  padding: 0px;
  display: flex;
  justify-content: center;
}

.formCol {
  max-width: 28rem;
}
.button-addon2 {
  padding: 0 8px;
}
.amountCard {
  height: max-content;
  border-radius: 10px;
  border-color: #00b4db;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0rem 1rem 0rem 1rem;
  width: 30rem;
}
.amountCard .row label {
  margin-bottom: 0px !important;
}

#inputFieldLabels {
  font-weight: 500;
}

select {
  max-width: max-content;
}

.classButtonGroup {
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.classButtonGroup .row {
  display: flex;
  justify-content: space-evenly;
}

.classButton {
  padding: 0px !important;
  width: 50px;
  height: 40px;
}
.inputFieldLabels {
  font-weight: 500;
}

.payment .pay_btn {
  width: 100%;
  font-weight: 600;
  background-color: #00b4db;
  border: #009cc0;
  margin-top: 2rem;
}
.payment .pay_btn:hover {
  background-color: #009cc0;
  border: #009cc0;
}
.payment_plan label {
  font-size: 2rem;
}
.payment_price_cut label {
  font-size: 1.2rem;
}
.price_cut {
  text-decoration: line-through;
  font-size: 1rem;
  color: #595959;
}
.form-control:focus {
  border: 2.0239px solid #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0;
}
.btn:focus {
  box-shadow: 0 0 0 0;
}
.form-control::-webkit-input-placeholder,
.PhoneInputInput::-webkit-input-placeholder {
  font-size: 0.8rem;
}

.form-control:-ms-input-placeholder,
.PhoneInputInput:-ms-input-placeholder {
  font-size: 0.8rem;
}

.form-control::placeholder,
.PhoneInputInput::placeholder {
  font-size: 0.8rem;
}
.classes-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
}
.classes-row > div {
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px 15px;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  font-weight: bolder;
  margin: 2px;
  width: 40px;
  padding: 8px;
}
.selected-class {
  border: 1px solid #00b4db !important;
  background: #00b4db;
  color: white;
}

.PhoneInputInput {
  border: 2.0239px solid #7f7f7f;
  padding: 0.1rem 0.75rem;
}
.PhoneInputCountry {
  border: 2.0239px solid #7f7f7f;
  padding: 0.2rem 0.5rem;
}
.PhoneInputInput:focus {
  border: 2.0239px solid #80bdff !important;
}

@media all and (max-width: 500px) {
  .payment_plan label {
    font-size: 1.3rem;
  }
  .payment_price_cut label {
    font-size: 1rem;
  }
  .jumboCard {
    width: 90vw;
  }
}
