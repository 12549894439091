.refer_modal .refer_bg {
  height: auto;
  width: 97%;
}
.refer_modal .refer_side {
  height: 104%;
  width: auto;
  position: absolute;
  top: -7px;
  right: -8px;
}

.refer_modal {
  position: relative;
}
.refer_info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.refer_info_left {
  position: absolute;
  top: 45px;
  left: 30px;
  width: 300px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.refer_info_right {
  position: absolute;
  top: 105px;
  width: 350px;
  font-size: 1.3rem;
  font-weight: 600;
  right: 80px;
}
.refer_info_icon {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 80px;
  font-size: 30px;
}
.refer_modal .join_msg {
  font-size: 1.8rem;
  color: #00708a;
}

.refer_off b {
  font-size: 50px;
}
.refer_modal_vl {
  position: absolute;
  border-left: 1px solid rgb(24, 24, 24);
  height: 100%;
  left: 50%;
  margin-left: -3px;
  top: 0;
}
.refer_info_left button {
  color: #fff;
  padding: 9px;
  border-radius: 10px;
  font-size: 1.2rem;
  width: 200px;
  background: rgb(124, 198, 255);
  background: linear-gradient(
    87deg,
    rgb(60, 171, 255) 0%,
    rgba(1, 137, 177, 1) 43%,
    rgba(0, 97, 125, 1) 100%
  );
}

@media all and (max-width: 500px) {
  .refer_info_left {
    top: 19px;
    left: 10px;
    width: 130px;
  }
  .refer_modal .join_msg {
    font-size: 0.8rem;
  }
  .refer_info_icon {
    top: 8px;
    right: 30px;
    font-size: 15px;
  }
  .refer_off b {
    font-size: 20px;
  }
  .refer_off {
    font-size: 8px;
  }
  .refer_info_left button {
    color: #fff;
    padding: 5px;
    border-radius: 10px;
    font-size: 0.75rem;
    width: 105px;
  }
}
