.refer-earn {
  padding-bottom: 250px;
}
.home-header {
  background-color: #00b4db;
  font-size: 2em;
  color: white;
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  padding-bottom: 40px;
  padding-top: 180px;
}

.content {
  padding: 0 10%;
  text-align: left;
  padding-bottom: 250px;
}

p {
  font-weight: 450;
}

.content-info {
  margin: 5% 0;
  margin-bottom: 10%;
}

.content-info h1 {
  font-size: 40px;
  font-weight: 700;
}

.content-info li {
  list-style: none;
  padding: none;
  margin: none;
}

.button-group {
  text-align: center;
  margin: 0 auto 3em;
  width: max-content;
  border: 2px solid #00b4db;
  border-radius: 12px;
  display: flex;
}

.button-group button {
  background-color: inherit;
  border-radius: 10px;
  width: 100px;
  padding: 10px;
  border: none;
  font-size: 24px;
  color: black;
  border-collapse: collapse;
  outline: none;
}

/* .button-group button:hover {
  background-color: #00b4db;
  color: white;
  transition: 0.2s ease-in-out all;
} */

.button-group .active {
  background-color: #00b4db;
  color: white;
}

#invite-login-div p {
  margin: 0 0 1.5em;
  font-size: 28px !important;
}

.works-list {
  display: flex;
  margin: 16px 0;
  align-items: center;
}

.works-list h2 {
  font-weight: 650;
  font-size: 1.75em;
  margin: 0;
}

.works-list p {
  margin: 10px 0;
}

#login {
  background-color: #5db11d;
  text-transform: uppercase;
  border-radius: 8px;
  width: 300px;
  border: none;
  font-size: 24px;
  padding: 10px;
  color: white;
  box-shadow: 2px 2px 10px #5db11d;
}

.TNC h2 {
  font-weight: bold;
  font-size: 30px;
  margin: 1em 0;
}

.TNC p {
  font-size: 1.3em;
}

@media only screen and (max-width: 600px) {
  .content {
    padding: 0 3%;
  }

  .content-info {
    margin: 2%;
    margin-bottom: 50px;
  }

  #invite-login-div p {
    margin: 0;
    font-size: 16px !important;
  }

  #login {
    width: 180px;
    margin: 0 auto;
    font-size: 18px;
  }

  #gift {
    display: none;
  }

  .content-info {
    font-size: 0.7em;
  }

  .content-info h1 {
    font-size: 2em;
  }

  #tnc {
    font-size: 0.8em;
  }

  .button-group button {
    width: 100px;
  }

  .works-list {
    margin: 20px 0;
  }

  .works-list img {
    width: 70px !important;
  }

  .works-list h2,
  .works-list p {
    font-size: 16px !important;
  }

  .TNC h2 {
    font-size: 1.5em;
  }

  .home-header {
    background-color: #00b4db;
    padding: 1em;
    color: white;
    font-size: 2em;
    text-align: center;
    padding-top: 100px;
  }
}
