.form-page-container{
    background: #d3eaf7;
    .form-items-div{
        background-color: #00b4db;
    }
    .brand{
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .brand-icon{
        width: 200px;
        cursor: pointer;
        
    }
    .form-container{
        background: white;
        margin: 5%;
        border-radius: 30px;
        position: relative;
        bottom: -150px;
        margin-top: -100px;
        svg{
            font-size: 28px;
            padding-left: 5px;
        }
       
    }
    .form-group{
        .PhoneInput{
            width: 100%;
            @media(max-width: 340px) {
                width: 90%;
            }
        } 
    }
    .form-header{
        text-align: center;
        font-weight: bolder;
        font-size: 35px;
        padding: 50px 0px;
    }
    .form-panel{
        display: flex;
        /* align-items: center; */
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .bookatrail__animation {
        width: 100% !important;
        outline-width: 0px;
    } 
    .bookatrail__animation > div{
        padding-top: 0px !important;
        outline-width: 0px;
    } 
    .bookatrail__animation > div > div{
        display: none;
    }
    .bookatrail__animation video{
        width: 50vw;
        outline-width: 0px;
    }
    .bookatrail__animation button{
        display: none;
    } 
    .form-right-panel{
        margin: 0px 10%;
        margin-left: 0px;
    }
    
    .form-input-group {
        margin-bottom: 15px;
        font-size: 18px;
    }
  .form-referral-input-container{
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 10px;
        outline-width: 0px;
        > .error{ color: red;}
        > .success{ color: green;}
         input{
            font-family: 'Poppins', sans-serif;
            outline-width: 0px;
            font-size: 15px;
            flex: 1;
            margin-right: 5px;
            width: 80%;
        }
        .PhoneInputCountry{ border: none !important; padding: 5px;}
        .PhoneInputCountrySelectArrow{display: none !important;}
        > button{
            font-size: 20px;
        }
    }

    .no-border{border: none !important;}
    .form-referral-input-container {        
        padding: 2px;
        > input{
            padding: 8px;
        }
        > button{
            font-family: 'Poppins', sans-serif;
            height: 40px;
            background: #5db11d;
            text-transform: uppercase;
            color: white;
            border-radius: 5px;
            border: none;
            outline-width: 0px;
            cursor: pointer;
            width: 80px;
            font-size: 15px;
            margin: 0px;
            font-weight: bold;    
        }
    }

    .classes-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px auto;
    }
    .classes-row > div{
        border:1px solid black;
        border-radius: 8px;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
        font-weight: bolder;
        margin: 2px;
        width: 40px;
        padding: 8px;
    }
    .selected-class{
        border: 1px solid #00b4db !important;
        background: #00b4db;
        color: white;
    }
    .form-radio-button-container{
        padding-left: 0px;
    }
    .radio-button-container{
        display: flex;
        align-items: center;
        width: 80px;
        margin-right: 20px;

    }
    .book-free-class{
        background: #00b4db;
        text-transform: uppercase;
        color: white;
        font-size: 20px;
        text-align: center;
        border: none;
        outline-width: 0px;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        margin: 5px auto;
        font-weight: bolder;
        cursor: pointer;
    }
    .form-footer-message{
        font-size: 12px;
        margin: 10px auto;
        width: 130%;
    }
    
    .cards-container{
        margin: auto 10%;
        border-radius: 20px;
        padding-top: 150px;
    }
    .card-header{
        text-align: center;
        font-weight: 600;
        font-size: 30px;
        margin-bottom: 50px;
        background-color: transparent; 
        border-bottom: none;
    }
    .cards-panel{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .cards-panel > div{
        width: 50%;
    }
    
    .card{
        display: flex;
        align-items: center;
        flex-direction: row;
        border: 1.5px solid #00b4db;
        background: white;
        border-radius: 20px;
        margin: 50px 25px;
        padding: 20px;
        justify-content: right;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        height: 170px;
    }
    .card  img{
        width: 100px;
        margin-right: 30px;
        margin-left: 20px;
    }
    .card h1{
        font-weight: bolder;
        font-size: 15px;
    }
    .cards-right-panel{
        margin-left: 10px;
    }
    @media(max-width: 990px) {
        .form-container{background: white;margin: 50px 20px;margin-top: -100px;padding: 20px;border-radius: 30px;}
        .form-header{text-align: center;font-weight: bolder;font-size: 20px;margin: 20px auto;padding: 0px;}    
        .form-left-panel {display: none;}
        .form-right-panel{margin: auto;}
        .form-panel{display: block;}
        .form-footer-message{width: 100%;}
        .card img{width: 70px;padding: 0px; margin: 0px; margin-right: 10px;}

    }
    @media(max-width: 780px) {

        .cards-container{margin: auto; padding-bottom: 50px;}
        .cards-panel{display: block;}
        .cards-panel > div{width: 100%;}
        .cards-right-panel{margin-left: 0px;}
        .card-header{font-size: 20px;}
    }

}

.ReactModal__Content {
    border: none !important; 
    background: transparent !important;
}
.ReactModal__Overlay {
    background: rgba(0,0,0,0.4) !important;
}