.box-shadow {
  box-shadow: 2px 2px 5px 4px #b0c2cd;
}

.homepage_container {
  background: #d7eefd;
}
.homepage__container p {
  font-size: min(max(1rem, 2vw), 1.2rem);
  line-height: 1.5;
}
.md_h2 {
  font-size: 2.6rem;
  font-weight: 700;
}
.md_h2ts {
  font-size: 2.6rem;
  font-weight: 700;
}

.md_p {
  font-size: 1.2rem;
}
.md_h4 {
  font-size: 1.5rem;
  font-weight: 700;
}
@media all and (max-width: 990px) {
  .md_h2 {
    font-size: 2rem;
    line-height: 1.3;
  }
  .md_h2ts {
    font-size: 1.8rem;
    line-height: 1.3;
  }
}
@media all and (max-width: 780px) {
  .md_h2 {
    font-size: 1.6rem;
  }
  .md_h2ts {
    font-size: 1.6rem;
  }
  .md_p {
    font-size: 1rem;
  }
  .md_h4 {
    font-size: 1.3rem;
  }
}
@media all and (max-width: 780px) {
  .md_h2 {
    font-size: 1.3rem;
  }
}
@media all and (max-width: 440px) {
  .md_h2 {
    font-size: 1rem;
  }
  .md_h2ts {
    font-size: 1rem;
  }
  .md_p {
    font-size: 0.8rem;
  }
  .md_h4 {
    font-size: 1rem;
  }
}
.homepage__top {
  background: url("../../Assets/homepage/homepage_base_background.png");
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}

.homepage__left__subheader h2 {
  font-size: 1.5rem;
}

.homepage__top__content {
  background: #d7eefd;
}
.homepage__view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 5% 100px 2%;
}
.homepage__base__left {
  color: white;
  padding: 20px;
  margin: 0px auto;
}
.homepage__left__header {
  font-size: 3.2rem;
  font-weight: bolder;
  line-height: 60px;
}
.homepage__left__header__box {
  width: fit-content;
}
.homepage__left__subheader h2 {
  text-align: left;
}

.homepage__tags__container {
  display: flex;
  align-items: center;
  margin: 15px auto;
  /* justify-content: space-around; */
}
.homepage__tags__container > p {
  background: #3c97cc;
  padding: 12px 7px;
  font-size: 10px;
  font-weight: 600;
  margin-right: 10px;
  border-radius: 2px;
}

@media (max-width: 560px) {
  .homepage__tags__container > p {
    font-size: 9px;
    padding: 10px 8px;
  }
}

@media (max-width: 450px) {
  .homepage__tags__container > p {
    font-size: 7px;
    padding: 6px;
  }
}

.homepage__bookatrial__container h3 {
  font-size: 1.5rem;
}
.homepage__bookatrial__input__container {
  display: flex;
  border-radius: 5px;
  background: white;
  margin: 10px auto;
  height: 45px;
  width: 85%;
  margin-left: 0;
}
.homepage__bookatrial__input__container input {
  border: none !important;
  outline-width: 0px !important;
  margin: 10px 0px;
}
.homepage__bookatrial__input__container button {
  width: 90px;
  background: #5db11d;
  color: white;
  border: none;
  font-weight: 600;
  outline-width: 0px;
  border-radius: 3px;
  margin: 5px 5px;
  font-size: 15px;
  cursor: pointer;
}
.homepage__bookatrial__input__container .PhoneInput {
  flex: 1 !important;
  width: 80% !important;
}
.homepage__bookatrial__input__container .PhoneInputCountry {
  border: none !important;
  border-right: 1px solid black !important;
  border-radius: 0px;
}
.homepage__bookatrial__input__container .PhoneInputCountryIcon {
  margin: 0px 15px;
}
.homepage__bookatrial__input__container .PhoneInputCountrySelectArrow {
  display: none;
}
.homepage__base__right {
  width: 650px;
  height: 600px;
}
.homepage__base__right > span > img {
  width: 650px;
  height: 565px;
}
@media all and (max-width: 1050px) and (min-width: 950px) {
  .homepage__base__right > span > img {
    width: 450px;
    height: 400px;
  }
}
@media all and (max-width: 550px) {
  .homepage__base__right > span > img {
    width: 450px;
    height: 400px;
  }
}
@media all and (max-width: 450px) {
  .homepage__base__right > span > img {
    width: 350px;
    height: 300px;
  }
}

.homepage__course__content {
  position: relative;
  top: -350px;
  margin-bottom: -500px;
  padding-bottom: 50px;
}
.homepage__base__bottom__circular__bar {
  position: relative;
  left: -200px;
  top: -120px;
  height: 350px;
  width: 350px;
}
.img__circular__bar {
  position: relative;
}
.homepage__base__bottom__yellow__left__circle__container {
  text-align: right;
}
.homepage__base__bottom__yellow__left__circle {
  position: relative;
  height: 220px;
  bottom: -100px;
}

.homepage__course__header {
  text-align: center;
  line-height: 45px;
  font-weight: 600;
}
.homepage__course__header h2 {
  font-weight: 700;
}
.homepage__course__cards__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 20px;
  padding: 0 5%;
}

.homepage__course__card {
  background: white;
  border-radius: 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding: 5px;
  box-shadow: 2px 2px 5px 4px #b0c2d2;
  flex: 0.3;
  min-width: 240px;
  margin: 20px;
  cursor: pointer;
}
.homepage__course__card:hover {
  background: #00b2d9;
  background: -webkit-linear-gradient(to bottom, #00b2d9, #0082af);
  background: linear-gradient(to bottom, #00b2d9, #0082af);
  color: white !important;
}
.homepage__course__name {
  text-align: center;
  font-weight: 700;
  margin-top: 10px;
  font-size: 1rem;
}
.homepage__course__card > img {
  width: 100px;
  height: 100px;
  margin: 15px auto;
}

.homepage__course__cards_button {
  text-align: center;
}
.homepage__course__cards_button > a:hover {
  color: #fff;
}
.homepage__course__cards_button > a {
  text-transform: uppercase;
  background: #5db11d;
  border: none;
  outline-width: 0px;
  padding: 10px 40px;
  color: white;
  font-weight: 600;
  width: 250px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 4px #b0c2d2;
  font-size: min(max(1rem, 2vw), 1.5rem);
  cursor: pointer;
}
.homepage__base__bottom__yellow__left__circle__container {
  text-align: right;
}
.homepage__base__bottom__yellow__left__circle {
  position: relative;
  height: 220px;
  width: 140px;
  bottom: -100px;
}

.homepage__coding__importance {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
}
.homepage__boy__with__phone__image {
  width: 720px;
  height: 900px;
  position: relative;
  left: -50px;
}

.homepage__coding__importance__left {
  margin-right: -50px;
}

.homepage__coding__importance__header {
  color: #00b2d9;
  font-size: min(max(1.5rem, 3.5vw), 2.6rem);
  line-height: 45px;
  font-weight: bolder;
  padding-right: 5%;
  padding-top: 50px;
}
.homepage__coding__importance__message {
  padding: 20px 0px;
  padding-right: 15%;
  padding-bottom: 50px;
}
.mobile__message {
  display: none;
}
.homepage__content__container {
  background: #fff;
}

.homepage__start__journey__button {
  background: #00b2d9;
  background: -webkit-linear-gradient(to right, #00b2d9, #0082af);
  background: linear-gradient(to right, #00b2d9, #0082af);
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  min-width: 250px;
  border: none;
  outline-width: 0px;
  border-radius: 5px;
  cursor: pointer;
}
.homepage__coding__importance__circular__bars__image {
  text-align: right;
  overflow: hidden;
}
.homepage__coding__importance__circular__bars__image > img {
  position: relative;
  right: -50px;
  width: 250px;
  height: 250px;
  width: 250px;
}
.homepage__coding__importance__right h2 {
  color: #00b2d9;
}
.homepage__coding__importance__readmore {
  color: #89dcfd;
  background: #0082af;
  font-size: 0.8rem;
  padding: 2px 5px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
}
.homepage__coding__importance__readmore:hover {
  color: #89dcfd;
  text-decoration: none;
}
.homepage__nurture__content {
  margin-top: -150px;
}
.homepage__nurture__header {
  font-weight: bold;
  text-align: center;
  padding: 0px 4rem;
}

@media screen and (max-width: 780px) {
  .homepage__nurture__header {
    padding: 0px 8rem;
  }
}
@media screen and (max-width: 660px) {
  .homepage__nurture__header {
    padding: 0px 2.5rem;
  }
}
@media screen and (max-width: 480px) {
  .homepage__nurture__header {
    padding: 0px 1rem;
  }
}

.typewriter {
  font-family: sans-serif;
  font-weight: 1000;
  font-size: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 7vh;
  border: 1px solid blue;
  background-color: #d7eefd;
  width: fit-content;
  margin: 20px auto;
  padding: 0px 10px;

  position: relative;
  width: fit-content;
  height: fit-content;
}

@media screen and (max-width: 980px) {
  .typewriter {
    font-size: 30px;
    min-height: 5vh;
  }
}
@media screen and (max-width: 768px) {
  .typewriter {
    font-size: 27px;
    min-height: 3.5vh;
  }
}
@media screen and (max-width: 480px) {
  .typewriter {
    font-size: 25px;
    min-height: 2.5vh;
  }
}
.box1,
.box2,
.box3,
.box4 {
  width: 10px;
  height: 10px;
  background-color: #3498db;
  position: absolute;
}

.box1 {
  top: -5px;
  left: -5px;
}

.box2 {
  top: -5px;
  right: -5px;
}

.box3 {
  bottom: -5px;
  left: -5px;
}

.box4 {
  bottom: -5px;
  right: -5px;
}

.homepage__nurture__cards__wrapper {
  background: #d7eefd;
  border-radius: 40px;
  margin: 25px 8%;
  margin-top: 70px;
}
.homepage__nurture__cards__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.homepage__nurture__card {
  display: block;
  text-align: center;
  font-size: 15px !important;
  margin: 2%;
  justify-content: space-evenly;
  align-items: center;
  color: #000;
  border-radius: 13px;
  flex-basis: 23%;
  font-weight: 200;
  flex-shrink: 1;
}
.homepage__nurture__card h4 {
  font-weight: 500;
}
.homepage__nurture__card > span > img {
  width: 120px;
  height: 122px;
}
@media all and (max-width: 500px) {
  .homepage__nurture__card > span > img {
    width: 100px;
    height: 100px;
  }
}

.homepage__nurture__diamond__dots__image {
  position: relative;
  top: 220px;
}
.homepage__nurture__diamond__dots__image > img {
  width: 230px;
  height: 230px;
}

.homepage__nurture__rectangular__image {
  position: relative;
  top: -50px;
  margin-bottom: -100px;
  text-align: right;
}
.homepage__nurture__rectangular__image > img {
  width: 200px;
  height: 200px;
}

.homepage__whylearnwithus {
  background: #eceff4;
  padding: 80px 10px;
  padding-bottom: 0px;
}
.homepage__whylearnwithus__header {
  text-align: center;
  padding: 0px 150px;
}
.homepage__whylearwithus__header1 {
  font-size: 40px;
  font-weight: bolder;
}
.homepage__whylearwithus__header2 {
  line-height: 22px;
  padding: 10px 20px 20px 10px;
}
.homepage__whylearnwithus__grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
}
.homepage__whylearnwithus__left > img {
  width: 650px;
  height: 500px;
}
.homepage__whylearnwithus__right {
  margin: 0px 60px;
}
.homepage__whylearnwithus__card {
  display: flex;
  margin: 20px auto;
}
.homepage__whylearnwithus__card img {
  width: 70px;
  /* after */
  height: 70px;
  margin-right: 30px;
}
.homepage__whylearnwithus__card__content {
  font-size: 15px;
  font-weight: 600;
}
.homepage__whylearnwithus__card__content p {
  margin-bottom: 0;
}
.homepage__whylearnwithus__card__header {
  font-weight: bold;
}
.homepage__whylearnwithus__card__header h4 {
  font-weight: 700;
}

.homepage__howtostart {
  background: white;
  padding: 20px;
  color: #00b2d9;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.homepage__broughttoyou {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  background: #eceff4;
  padding: 15px;
  padding-bottom: 280px;
  padding-top: 100px;
}
.homepage__broughttoyou h5 {
  font-weight: 700;
  font-size: 2rem;
}
.homepage__broughttoyou__grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 60px;
  margin-left: 5%;
  margin-right: 5%;
}
.homepage__broughttoyou__card > img {
  width: 130px;
  height: 130px;
}

.homepage__broughttoyou__stats {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background: white;
  margin: auto 5%;
  border-radius: 40px;
  padding: 30px 0px;
}
.homepage__broughttoyou__stats__card {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  margin: 20px auto;
}
.homepage__howtostart img {
  width: 100%;
  height: 100%;
}

.homepage__broughttoyou__stats__card img {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}
.homepage__broughttoyou__stats__card h1 {
  margin: 0px;
  font-size: 40px;
}
.homepage__broughttoyou__stats__card .countup span {
  font-weight: 600;
  display: block;
  font-size: 2.3rem;
}
@media all and (max-width: 990px) {
  .top__navbar__login {
    background-color: #095480;
  }
  .homepage__view {
    display: block;
    padding: 10px;
    padding-top: 100px;
  }
  .homepage__top {
    padding-bottom: 200px;
  }
  .homepage__left__header {
    font-size: 2rem;
    line-height: 55px;
    margin-bottom: 10px;
  }

  .homepage__base__right {
    width: 100%;
  }
  .homepage__bookatrial__input__container input {
    width: 100%;
    margin: auto 0px;
  }
  .homepage__course__header {
    font-size: 20px;
    line-height: 20px;
  }
  .homepage__course__cards__container {
    padding: 0px;
    margin: 50px 0px;
  }

  .homepage__base__bottom__circular__bar,
  .homepage__base__bottom__yellow__left__circle__container {
    display: none;
  }
  .homepage__course__content {
    position: static;
    top: 0px;
    margin-bottom: 50px;
  }
  .homepage__course__card > img {
    width: 80px;
    height: 80px;
  }

  .homepage__coding__importance {
    flex-direction: column-reverse;
    text-align: center;
    padding-top: 0px;
  }
  .homepage__coding__importance__left {
    margin: 0;
  }
  .homepage__coding__importance__right {
    padding: 10px;
    width: 80%;
  }
  .homepage__coding__importance__circular__bars__image {
    display: none;
  }
  .homepage__coding__importance__header {
    font-size: 30px;
    line-height: 30px;
    padding-right: 0px;
  }

  .homepage__coding__importance__message {
    padding-right: 0px;
    font-weight: 600;
    margin-bottom: 50px;
  }
  .homepage__boy__with__phone__image {
    width: 650px;
    height: 800px;
  }

  .homepage__nurture__content {
    margin-top: 100px;
  }
  .homepage__whylearwithus__header2 {
    font-weight: 500;
  }

  .homepage__nurture__hidden__header {
    display: block;
    font-size: 15px;
    line-height: 20px;
  }
  .homepage__nurture__diamond__dots__image,
  .homepage__nurture__rectangular__image {
    display: none;
  }

  .homepage__whylearnwithus__header {
    text-align: center;
    padding: 0px 20px;
  }
  .homepage__whylearwithus__header1 {
    font-size: 30px;
  }
  .homepage__whylearnwithus__grid {
    display: block;
    text-align: center;
  }

  .homepage__whylearnwithus__right {
    margin: 5px auto;
    padding-bottom: 10px;
  }
  .homepage__whylearnwithus__card {
    margin: 30px 10px;
    text-align: left;
  }

  .homepage__howtostart {
    font-size: 20px;
  }
  .homepage__howtostart__image__container {
    width: 100%;
    overflow-x: scroll;
  }
  .homepage__howtostart__image__container::-webkit-scrollbar {
    display: none;
  }
  /* .homepage__howtostart img {
    height: 300px;
    width: 750px;
  } */

  .homepage__broughttoyou {
    font-size: 25px;
  }
  .homepage__broughttoyou__stats {
    box-shadow: none !important;
    background: transparent;
  }
  .homepage__broughttoyou__stats__card img {
    width: 100px;
    height: 110px;
  }
  .homepage__broughttoyou__stats__card h1 {
    font-size: 30px;
  }
  .homepage__ischildready {
    display: block;
    padding: 30px 20px;
  }
  .homepage__ischildready > div {
    width: 100%;
    text-align: center;
  }
  .homepage__ischildready__left > h1 {
    font-size: 25px;
    line-height: 25px;
  }
}
@media all and (max-width: 780px) {
  .mobile__message {
    display: block;
  }
  .homepage__broughttoyou h5 {
    font-size: 1.3rem;
  }
  .desktop__message {
    display: none;
  }
  .homepage__course__card {
    min-width: 38%;
    margin: 15px;
  }
  .homepage__top {
    padding-bottom: 100px;
  }
  .homepage__left__subheader h2 {
    font-size: 1.2rem;
  }
  .homepage__bookatrial__container h3 {
    font-size: 0.9rem;
  }
  .homepage__whylearnwithus__left > img {
    width: 400px;
    height: 300px;
  }
  .homepage__boy__with__phone__image {
    width: 620px;
    height: 900px;
  }
  .homepage__top {
    background-size: 200% 119%;
    padding-bottom: 150px;
  }
}
@media all and (max-width: 500px) {
  .homepage__nurture__card {
    width: 40%;
    padding: 10px;
  }
  .homepage__whylearnwithus__left > img {
    width: 320px;
    height: 250px;
  }
  .homepage__boy__with__phone__image {
    position: static;
    left: 0;
    width: 460px;
    height: 650px;
  }
  .homepage__howtostart img {
    height: 300px;
    width: auto;
    overflow-x: scroll;
  }
}

@media all and (max-width: 440px) {
  .homepage__nurture__card > span > h4 {
    font-size: 0.9rem !important;
  }
  .homepage__nurture__cards__wrapper {
    margin: 25px 5%;
  }
  .homepage__coding__importance__readmore {
    font-size: 0.8rem;
  }
  .homepage__coding__importance__message {
    padding-bottom: 0px;
  }
  .homepage__start__journey__button {
    font-size: 12px;
    padding: 10px 10px;
    min-width: 0;
  }
  .homepage__bookatrial__input__container {
    height: 40px;
    width: 100%;
  }
  .homepage__bookatrial__input__container button {
    width: 80px;
    font-size: 12px;
  }
  .homepage__bookatrial__input__container .PhoneInputCountry {
    padding: 0rem 0rem;
  }
  .homepage__view {
    display: block;
    padding: 10px;
    padding-top: 100px;
  }
  .homepage__top {
    padding-bottom: 0px;
    background-size: 200% 120%;
  }
  .homepage__base__right {
    height: 400px;
  }
  .homepage__left__header {
    font-size: 1.65rem;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .homepage__left__subheader h2 {
    font-size: 0.8rem;
  }
  .homepage__bookatrial__container h3 {
    font-size: 0.8rem;
  }

  .homepage__base__right {
    width: 100%;
  }

  .homepage__course__header {
    font-size: 20px;
    line-height: 20px;
  }
  .homepage__course__cards__container {
    padding: 0px;
    margin: 50px 0px;
  }
  .homepage__course__card {
    min-width: 38%;
  }
  .homepage__base__bottom__circular__bar,
  .homepage__base__bottom__yellow__left__circle__container {
    display: none;
  }
  .homepage__course__content {
    position: static;
    top: 0px;
    margin-bottom: 50px;
  }

  .homepage__coding__importance__left {
    margin: 0px;
  }

  .homepage__coding__importance__circular__bars__image {
    display: none;
  }
  .homepage__coding__importance__header {
    line-height: 30px;
    padding-right: 0px;
  }

  .homepage__coding__importance__message {
    padding-right: 0px;
  }
  .homepage__whylearnwithus__header {
    text-align: center;
    padding: 0px 20px;
  }

  .homepage__whylearwithus__header2 {
    padding: 0px;
  }
  .homepage__whylearnwithus__grid {
    display: block;
    text-align: center;
  }

  .homepage__whylearnwithus__right {
    margin: 5px auto;
    padding-bottom: 10px;
  }
  .homepage__whylearnwithus__card {
    margin: 30px 10px;
    text-align: left;
  }

  .homepage__howtostart {
    font-size: 20px;
  }
  .homepage__howtostart__image__container {
    width: 100%;
    overflow-x: scroll;
  }
  .homepage__howtostart__image__container::-webkit-scrollbar {
    display: none;
  }
  /* .homepage__howtostart img {
    width: 100px;
    height: 300px;
    width: auto;
    overflow-x: scroll;
  } */

  .homepage__broughttoyou {
    font-size: 25px;
  }
  .homepage__broughttoyou__stats {
    box-shadow: none !important;
    background: transparent;
  }
  .homepage__broughttoyou__stats__card img {
    width: 100px;
    height: 100px;
  }
  .homepage__broughttoyou__stats__card h1 {
    font-size: 30px;
  }
  .homepage__ischildready {
    display: block;
    padding: 30px 20px;
  }
  .homepage__ischildready > div {
    width: 100%;
    text-align: center;
  }
  .homepage__ischildready__left > h1 {
    font-size: 25px;
    line-height: 25px;
  }
  .homepage__course__name {
    font-size: 0.7rem;
  }
  .homepage__broughttoyou__stats__card .countup p {
    font-size: 1rem;
  }

  .homepage__boy__with__phone__image {
    width: 360px;
    height: 600px;
  }
  .homepage__course__card > img {
    width: 52px;
    height: 52px;
    margin: 5px auto;
  }
}
@media all and (max-width: 365px) {
  .homepage__boy__with__phone__image {
    width: 350px;
    height: 530px;
  }
}
