.ischildready {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #00b2d9;
  background: -webkit-linear-gradient(to left, #00b2d9, #0082af);
  background: linear-gradient(to left, #00b2d9, #0082af);
  padding: 50px 50px 0px 50px;
  margin: 0px 6%;
  border-radius: 20px;
  position: relative;
  top: -200px;
  margin-bottom: -200px;
}
.ischildready h2 {
  font-weight: 700;
}
.ischildready span {
  display: block;
  margin-top: 1rem;
}
.ischildready > div {
  width: 50%;
}
.ischildready__left {
  text-align: left;
  font-size: 25px;
  color: white;
  font-weight: normal;
  padding-left: 50px;
}
.ischildready__left > h2 {
  font-size: 2.8rem;
  color: white;
  margin: 0px;
}
.ischildready__left > button {
  background: white;
  cursor: pointer;
  color: #0082af;
  padding: 10px;
  width: 150px;
  border-radius: 5px;
  margin: 20px auto;
  font-size: 20px;
  font-weight: bold;
}
.ischildready__right {
  display: flex;
}
.ischildready__right > img {
  width: 500px;
  height: 300px;
  margin: auto;
}
@media (max-width: 640px) {
  .ischildready__right > img {
    width: 300px;
    height: 200px;
  }
}
.footer {
  background: #252525;
  color: white;
  padding-bottom: 0px;
}
.footer__content {
  padding: 5% 8%;
  padding-bottom: 0px;
}
.footer__contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px auto;
  margin-right: 30px;
}
.footer__contact__left {
  font-size: 35px;
  line-height: 40px;
  font-weight: 600;
}
.footer__contact__right {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: white;
  color: black;
  padding: 15px 50px;
  border-radius: 20px;
  font-size: 15px;
}
.footer__contact__right > div > span {
  display: block;
  margin-bottom: 10px;
}
.footer__contact__right > div > a {
  display: block;
  color: #0090bc !important;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.footer__moreinfo__links > a:hover {
  color: #fff;
}
.footer__contact__right > div > a > img {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
}
.footer__separator {
  width: 85%;
  height: 1px;
  margin: 0px auto;
  background: white;
}
.footer__moreinfo {
  padding: 50px 5%;
  display: flex;
  justify-content: space-between;
  background: #252525;
}
.footer__moreinfo__col {
  color: white;
  margin: 0px 10px;
}
.footer__moreinfo__col > .footer__moreinfo__col__title {
  font-size: 20px;
  font-weight: bold;
}
.footer__moreinfo > .col1 > img {
  width: 250px;
  height: 70px;
  margin: 5px;
}
.footer__social {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.footer__social img {
  width: 35px;
  height: 35px;
  margin: 0px 10px;
}
.footer__moreinfo > .col2 {
  font-size: 20px;
  color: white;
  text-decoration: none;
}
.footer__moreinfo__links {
  align-items: center;
  justify-content: space-evenly;
}
.footer__moreinfo__links > a {
  color: white;
  font-size: 15px;
  text-decoration: none;
  display: block;
  margin: 10px auto;
}
.footer__moreinfo > .col4 > img {
  width: 400px;
  height: 200px;
}

.footer__madewith {
  background: black;
  color: white;
  text-align: center;
  font-size: 17px;
  padding: 10px;
}
.footer__madewith > span {
  color: red;
}

@media (max-width: 990px) {
  .ischildready {
    display: block;
    padding: 30px 20px 0px;
  }
  .ischildready > div {
    width: 100%;
    text-align: center;
  }
  .ischildready__left {
    padding: 0px;
  }
  .ischildready__left > h2 {
    font-size: 30px;
  }
  .footer__contact {
    margin-right: 0px;
  }
  .footer__contact__left {
    font-size: 30px;
  }
  .footer__contact__right {
    margin: 10px auto;
    padding: 0px 10px;
    width: 100%;
  }
  .footer__contact__right > div {
    margin: 15px;
  }
  .footer__contact__right a {
    font-size: 18px !important;
  }
  .footer__moreinfo {
    display: block;
  }
  .footer__moreinfo__col > h1 {
    margin-left: 10px;
  }
  .footer__moreinfo > div {
    margin: 20px auto;
  }
  .footer__social {
    justify-content: flex-start;
  }
  .footer__moreinfo__links {
    text-align: left;
  }
  .footer__moreinfo__links > a {
    display: inline-block;
    margin: 10px;
  }

  .footer__moreinfo > .col4 > img {
    width: 500px;
    height: 250px;
  }
}
@media (max-width: 990px) {
  .ischildready h4 {
    font-size: 1.2rem;
  }
}
@media (max-width: 780px) {
  .ischildready span {
    font-size: 1.2rem;
  }
  .ischildready__left > button {
    padding: 4px;
    font-size: 1.2rem;
    width: 130px;
  }
}
@media (max-width: 540px) {
  .ischildready__right > img {
    height: 150px;
    width: 250px;
  }
  .footer__moreinfo > .col4 > img {
    width: 300px;
    height: 150px;
  }
}
