
:root {
  --blue: #0e0620;
  --white: #fff;
  --green: #2ccf6d;
}

.error404_container {
  height: 100vh;
  width: 80%;
  margin: auto;
}
.error404_container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito Sans";
  color: var(--blue);
  font-size: 1em;
}

.error404_container ul {
  list-style-type: none;
  padding-inline-start: 35px;
}
.error404_container svg {
  width: 100%;

}
.error404_container h1 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight: bold;
}
.error404_container h2 {
  font-weight: bold;
}
.error404_container .hamburger-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 35px;
  z-index: 2;

  & button {
    position: relative;
    width: 30px;
    height: 22px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    & span {
      position: absolute;
      height: 3px;
      background: #000;
      width: 100%;
      left: 0px;
      top: 0px;
      transition: 0.1s ease-in;
      &:nth-child(2) {
        top: 9px;
      }
      &:nth-child(3) {
        top: 18px;
      }
    }
  }
  & [data-state="open"] {
    & span {
      &:first-child {
        transform: rotate(45deg);
        top: 10px;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 10px;
      }
    }
  }
}

.error404_container .btn {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
  &.green {
    border: 4px solid var(--green);
    color: var(--blue);
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background: var(--green);
      z-index: -1;
      transition: 0.2s ease;
    }
    &:hover {
      color: var(--white);
      background: var(--green);
      transition: 0.2s ease;
      &:before {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {

  .error404_container {

    margin-bottom: 100px;

  }
  .error404_container h1 {
    font-size: 5em;
  
}
.error404_container h2 {
  font-size: 1.5rem;
}
.error404_container p {
  font-size: 1;
}
}
