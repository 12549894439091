.top__navbar a:hover {
  color: #fff;
}
.top__desktop__navbar {
  background: transparent;
  width: -webkit-fill-available;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4% 0;
  color: white;
}
.sticky__navbar {
  box-shadow: 2px 2px 5px 4px #b0c2cd;
  color: black;
  margin: 0px !important;
  padding: 10px 40px;
  background: white !important;
  z-index: 10;
  position: fixed;
  padding: 1.2% 0px;
}
.sticky__navbar a {
  color: black !important;
}

.sticky__navbar .top__navbar__brand__image {
  display: none;
  
}
.sticky__navbar .top__navbar__brand__sticky__image {
  display: block !important;
}
.top__navbar__brand img {
  width: 140px;
  height: 40px;
  margin-bottom: 0.5rem;
}
.top__navbar__brand__sticky__image {
  display: none;
}
.top__navbar__options > a {
  color: white;
  font-size: 15px;
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0px 30px;
  cursor: pointer;
  min-width: 100px;
}

.top__navbar__login {
  text-transform: uppercase;
  color: white;
  background: #007cb2;
  padding: 8px 25px;
  border-radius: 19px;
  font-weight: 500;
  cursor: pointer;
  margin: 0px 20px;
}
.top__navbar__bookatrial {
  color: #5db11d;
  background: white;
  padding: 8px 25px;
  border-radius: 19px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}
.sticky__navbar .top__navbar__bookatrial {
  color: white !important;
  background: #5db11d !important;
}

.top__navbar__submenu {
  text-align: center;
  position: absolute;
  margin-top: 25px;
  margin-left: 10px;
  min-width: 100px;
}
.top__navbar__submenu__tip {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid white;
  margin: 0px auto;
}
.top__navbar__submenu__items {
  border-radius: 5px;
  background: white;
  padding: 20px 15px;
}
.top__navbar__submenu a {
  color: black !important;
  display: block;
  text-decoration: none;
  font-size: 15px;
  font-weight: 550;
  padding: 5px;
}

.top__mobile__navbar {
  position: absolute;
  width: -webkit-fill-available;
  color: white;
  background: transparent;
  padding: 20px;
  z-index: 1;
}
.top__mobile__navbar__header {
  display: flex;
  align-items: center;
  width: 100%;
}
.top__mobile__navbar__toggler {
  width: 30px;
  cursor: pointer;
}
.top__mobile__navbar__brand__container {
  flex: 1;
  text-align: center;
}
.top__mobile__navbar__brand {
  width: 150px;
  height: 45px;
}
.top__mobile__navbar__content {
  background-color: #fff;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
}
.top__mobile__navbar__content a {
  color: #000 !important;
  display: block;
  margin: 10px 5px;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
}
.top__mobile__navbar__content a:nth-child(4) {
  margin-bottom: 20px;
}
.top__mobile__navbar__content .top__navbar__login {
  margin-left: 0px !important;
}
.top__mobile__navbar__content .top__navbar__bookatrial {
  color: #ffffff;
  background-color: #5db11d;
}
.top__mobile__navbar__content .top__navbar__buttons {
  border-top: 1px solid rgb(173, 173, 173);
  padding-top: 20px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
}

.top__mobile__navbar__content .top__navbar__login {
  margin-bottom: 15px;
  width: 100%;
  border-radius: 5px;
}
.top__mobile__navbar__content .top__navbar__bookatrial {
  margin-bottom: 15px;
  width: 100%;
  border-radius: 5px;
}
.top__mobile__navbar__submenu {
  margin-bottom: 20px;
}
.top__mobile__navbar__submenu > a {
  font-size: 12px;
  font-weight: normal;
  margin: 8px 5px;
}

.top__mobile__navbar {
  display: none;
}
@media all and (max-width: 1210px) {
  .top__navbar__options > a {
    margin: 0px 10px;
  }
  .top__navbar__login {
    padding: 3px 19px;
    margin: 0px 15px;
  }
  .top__navbar__bookatrial {
    padding: 3px 19px;
  }
}
@media all and (max-width: 990px) {
  .top__desktop__navbar {
    display: none;
  }
  .top__mobile__navbar {
    display: block;
  }
  .top__navbar__buttons > a {
    display: inline-block;
    margin: 0px;
  }
}
.homepage__base {
  padding-top: 100px;
}
