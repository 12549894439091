/* .countdown{
    background-color: #c3c7ed;
    height: 100vh;
}

.box{
    position:absolute;
    top: 50%;
    left: 50%;
    width:30rem;
    height:26rem;
    margin-top: -13rem; 
    margin-left: -15rem; 
    border: 1px solid #ccc;
    border-radius: 0.8rem;
    background-color: #e5e7fb;
}

.text{
    position: relative;
    float: left;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #7a82d0;
    font-size: xx-large;
}


.referralCode{
    position: relative;
    float: left;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #7a82d0;
    font-size: 1.2rem;
    text-align: center;
}

.button{
    position: relative;
    float: left;
    top: 128%;
    left: 15%;
    transform: translate(-50%, -50%);
    color: #7a82d0;
    font-size: xx-large;
}

.btn{
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid #7a82d0;
    width: 10rem;
}

.btn:hover{
    background-color: #7a82d0;
    color: white;
  transition: all 0.4s ease-out;
}

.counter{
    background-color: white;
    height: 8rem;
    width: 40rem;
    position: relative;
    float: left;
    top: 20%;
    left: -16%;
    border-radius: 1rem;
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    padding: 1rem 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.timer{
    position: relative;
    float: left;
    top: 14%;
    left: 0%;
}

.clock{
    position: relative;
    margin: a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.clock section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.clock section p{
    font-size: 3rem;
    margin-bottom: 0rem;
    color: #7a82d0;
}

.clock section small{
    color: #808080;
    text-shadow: none;
    font-size: larger;
}

.dots{
    padding-bottom: 1.3rem;
    font-size :x-large;
}


@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {
    .box{
        position:absolute;
        top: 50%;
        left: 50%;
        width:23rem;
        height:22rem;
         margin-top: -11rem; 
        margin-left: -11rem; 
        border: 1px solid #ccc;
        border-radius: 0.8rem;
        background-color: #e5e7fb;
    }

    .text{
        font-size: 2rem;
    }

    .counter{
        background-color: white;
        height: 7rem;
        width: 30rem;
        position: relative;
        float: left;
        top: 20%;
        left: -16%;
        border-radius: 1rem;
        display: flex;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        padding: 1rem 2rem;
    }

    .referralCode{
        position: relative;
        float: left;
        top: 34%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #7a82d0;
        font-size:1rem;
    }

    .button{
        position: relative;
        float: left;
        top: 147%;
        left: 16%;
        transform: translate(-50%, -50%);
        color: #7a82d0;
        font-size: small;
    }
    .clock section p{
        font-size: 1.5rem;
    }
    .btn{
        width: 8rem;
    }
    .timer{
        position: relative;
        float: left;
        top: 25%;
        left: 0%;
    }
    
 }

@media all and (max-width: 480px) { 
    .box{
        position:absolute;
        top: 50%;
        left: 50%;
        width:17rem;
        height:20rem;
         margin-top: -10rem; 
        margin-left: -8rem; 
        border: 1px solid #ccc;
        border-radius: 0.8rem;
        background-color: #e5e7fb;
    }

    .text{
        font-size: 1.5rem;
    }
    .counter{
        background-color: white;
        height: 5rem;
        width: 21rem;
        position: relative;
        float: left;
        top: 22%;
        left: -12%;
        border-radius: 1rem;
        display: flex;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        padding: 1rem 2rem;
    }
    .referralCode{
        position: relative;
        float: left;
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #7a82d0;
        font-size:0.8rem;
        text-align: center;
    }

    .button{
        position: relative;
        float: left;
        top: 243%;
        left: 30%;
        transform: translate(-50%, -50%);
        color: #7a82d0;
        font-size: large;
    }
    
    .clock section p{
        font-size: 1rem;
    }

    .clock section small{
        font-size:medium;
    }

    .btn{
        font-size:small;
    }

    .timer{
        position: relative;
        float: left;
        top: 25%;
        left: 0%;
    }
}
 */


 .countdown{
    background-color: rgb(195,199,237);
    height: 100vh;
    width: 100vw;
 }

 .box{
     position:absolute;
    top: 10%;
    left: 25%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 50%;
    margin:auto ;
    padding: 2rem 0rem;
    background-color: rgb(229,231,251);
    border-radius: 0.8rem;
 }

.text{
    color: #7a82d0;
    background-color: rgb(229,231,251);

    font-size: xx-large;
    width: fit-content;
    margin: auto;
    font-weight: bold;

}

.referralCode{
    background-color: rgb(229,231,251);
    width: fit-content;
    margin: auto;
    color: #7a82d0;
    font-size: 1.2rem;
    text-align: center;
}


.button{
    width: fit-content;
    margin: auto;
    color: #7a82d0;
    font-size: large;
    background-color: rgb(229,231,251);
}

.btnc{
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid #7a82d0;
    width: 10rem;
    padding: 0.5rem;
}

.btnc:hover{
    background-color: #7a82d0;
    color: white;
  transition: all 0.4s ease-out;
}



.counter{
    background-color: white;
   
    height: fit-content;
    width: 120%;
    /* position: relative;
    float: left;
    top: 20%;
    left: -23%; */
    border-radius: 1rem;
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    padding: 1rem 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -4rem;
}

.timer{
 background-color: #fff;
}

.clock{
    position: relative;
    margin: a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: #fff;
}

.clock section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    background-color: #fff;

}

.clock section p{
    font-size: 3rem;
    margin-bottom: 0rem;
    color: #7a82d0;
    background-color: #fff;

}

.clock section small{
    color: #808080;
    text-shadow: none;
    font-size: larger;
    background-color: #fff;

}

.dots{
    padding-bottom: 1.3rem;
    font-size :x-large;
    background-color: #fff;

}


@media all and (min-width: 1024px) and (max-width: 1280px) { }

@media all and (min-width: 768px) and (max-width: 1024px) {
    .counter{
        width: 127%;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .box{
        position:absolute;
        top: 10%;
        left: 25%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 50%;
        margin:auto ;
        padding: 2rem 0rem;
        background-color: rgb(229,231,251);
        border-radius: 0.8rem
    }

    .text{
        font-size: 1.5rem;
    }

    .referralCode{
        background-color: rgb(229,231,251);
    width: fit-content;
    margin: auto;
    color: #7a82d0;
    font-size: 0.8rem;
    text-align: center;
    }

    .clock section p{
        font-size: 1.5rem;
    }
    .btnc{
        width: 8rem;
    }

    
.counter{
    width: 140%;
    margin-left: -4rem;
}

    
 }

@media all and (max-width: 480px) { 
    .box{
        position:absolute;
        top: 10%;
        left: 20%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 60%;
        margin:auto ;
        padding: 2rem 0rem;
        background-color: rgb(229,231,251);
        border-radius: 0.8rem;
    }

    .text{
        font-size: 1rem;
    }
    .counter{
        background-color: white;
        width: 140%;
        margin: auto;
        /* position: relative;
        float: left;
        top: 20%;
        left: -23%; */
        border-radius: 1rem;
        display: flex;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        padding: 1rem 3rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: -3rem;
    }
    .referralCode{
        background-color: rgb(229,231,251);
        width: fit-content;
        margin: 0rem 1rem;
        color: #7a82d0;
        font-size: 0.8rem;
        text-align: center;
    }

    .button{
        width: fit-content;
        margin: auto;
        color: #7a82d0;
        font-size: x-large;
        background-color: rgb(229,231,251);
    
    }
    
    .clock section p{
        font-size: 1rem;
    }

    .clock section small{
        font-size:medium;
    }

    .btnc{
        font-size:small;
    }

    .timer{
        position: relative;
        float: left;
        top: 25%;
        left: 0%;
    }
}

.text-sz{
    font-size: 0.8rem;
}