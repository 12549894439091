/* @import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700"); */
.firstSection {
	padding: 2%;
	background-image: linear-gradient(#3a073b, #650a72);
}
.main-heading {
	margin: 5% 5%;
}
.search-field {
	background-color: #0000006b;
	margin-top: 2%;
	padding: 0.6%;
	border-radius: 16px;
	width: 85%;
}

.search-icon span {
	color: white;
	width: 20%;
	border: none;
	background-color: transparent;
}
.search-input {
	font-size: 120%;
	color: white;
	border: none;
	background-color: transparent;
}
.search-input::placeholder {
	font-size: 120%;
	color: rgba(255, 255, 255, 0.336);
}
.search-input:focus {
	border: none;
	color: white;
	background-color: transparent;
}
.blog_img {
	width: 30%;
	height: 20%;
}
.textBlog {
	font-size: 120%;
}
.topic-btn-div {
	width: 80%;
}
.topic-btn {
	/* background-image: linear-gradient(#3a073b, #650a72); */
	background-color: white;
	color: black;
	margin: 2% 1%;
	padding: 0.3%;
	padding-left: 4%;
	padding-right: 4%;
	border-radius: 10px;
	box-shadow: 2px 2px 5px rgb(104, 104, 104);
	// width: 13%;
}
.topic-btn-clicked {
	background-image: linear-gradient(#3a073b, #650a72);
  /* background-color: white; */
  color: white;
  margin: 2%;
  padding: 0.3%;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgb(104, 104, 104);
  // width: 13%;
}
.primary-blog {
	width: 75%;
	margin: auto;
	margin-top: 5%;
	margin-bottom: 5%;
	padding-bottom: 20px;
	padding-left: 20px;
	position: relative;
}
.primary-blog-img-div {
	width: 70%;
}
.primary-blog-img-div img {
	border-radius: 15px;
}
.primary-blog-content-div {
	width: 50%;
	padding-right: 15px;
	padding-left: 20px;
}
.single-blog {
	margin-bottom: 5%;
	// max-width: 385px;
	padding-top: 30px;
	padding-bottom: 30px;
}
.primary-blog-heading {
	// font-size: 2.5rem;
	font-size: 2.3vw;
	font-weight: bolder;
	padding-top: 15px;
	// font-family: sans-serif;
	line-height: 1.1;
}
.main-para {
	// font-size: 1.3rem;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;

	font-size: 1.4vw;
}
.secondary-heading {
	font-size: 1.6vw;
}
.para {
	// font-size: 1.1rem;
	font-size: 1.1vw;
}
.secondary-img {
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: 22vw;
	border-radius: 10px;
}
.author-blog {
	// font-size: 1rem;
	position: absolute;
	bottom: 15px;
	font-size: 1.12vw;
}
.Diffblog {
	margin-top: 20px;
	font-size: 3vw;
	line-height: 1.2;
	// text-align: center;
}
.Diffblogcont {
	margin-top: 0px;
	font-size: 2vw;
	// text-align: center;
}
.inner-blogs-name {
	padding-top: 0%;
	font-size: 1.16vw;
	padding-left: 3%;
	margin-bottom: 5%;
}
.singleBlogPara {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
@media screen and (max-width: 1000px) {
	.author-blog {
		// font-size: 1rem;
		font-size: 1.5vw;
	}
	.secondary-heading {
		font-size: 2.2vw;
	}
	.para {
		// font-size: 1.1rem;
		font-size: 1.5vw;
	}
	// .single-blog
	// {
	//   max-width:0% ;
	// }
	.secondary-img {
		width: 30vw;
		border-radius: 10px;
	}
	.primary-blog-img-div {
		width: 95%;
		border-radius: 10px;
	}
	.primary-blog-heading {
		font-size: 2.2vw;
		font-weight: bolder;
		padding-top: 5px;

		line-height: 1.1;
	}
	.primary-blog-content-div {
		width: 70%;

		padding-right: 15px;
		padding-top: 0px;
	}
	.main-para {
		margin-top: -32px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	.inner-blogs-name {
		padding-top: 0%;
		font-size: 1.62vw;
		padding-left: 3%;
		margin-bottom: 5%;
	}
}

@media screen and (max-width: 780px) {
	.author-blog {
		// font-size: 1rem;
		font-size: 1.8vw;
	}
	.primary-blog {
		flex-direction: column;
		// justify-content: center;
		align-items: center;
		width: 80%;
		padding-bottom: 4rem;
	}

	.primary-blog-img-div {
		width: 80%;
	}
	.primary-blog-content-div {
		width: 80%;
	}
	.primary-blog-heading {
		// font-size: 2.5rem;
		margin-top: 2%;
		font-size: 3.5vw;
		margin-bottom: 0% !important;
	}
	.main-para {
		// font-size: 1.3rem;
		font-size: 2.2vw;
	}
	.secondary-heading {
		font-size: 3vw;
	}
	.para {
		// font-size: 1.1rem;
		font-size: 2vw;
	}
	.main-para {
		padding-top: 25px;
	}
	.inner-blogs-name {
		padding-top: 0%;
		font-size: 2.1vw;
		padding-left: 5%;
		margin-bottom: 5%;
	}
}
.primary-blog:hover {
	box-shadow: 2px 2px 5px 4px #b0c2d2;
	border-radius: 15px;
}

@media screen and (max-width: 600px) {
	.primary-blog {
		width: 80%;
		padding-right: 20px;
	}
	.inner-blogs-name {
		padding-top: 2%;
		font-size: 2.62vw;
		padding-left: 5%;
		margin-bottom: 5%;
	}
	.primary-blog-heading {
		font-size: 5vw;
	}
	.main-para {
		font-size: 3vw;
	}
	.secondary-heading {
		font-size: 3vw;
	}
	.para {
		// font-size: 1.1rem;
		font-size: 2.2vw;
	}
	.author-blog {
		font-size: 2vw;
	}
}
.single-blog:hover {
	// background: #00b2d9;
	// background: -webkit-linear-gradient(to bottom, #b0c2d2, #b0c2d2);
	// background: linear-gradient(to bottom, #b0c2d2, #b0c2d2);
	// color: white;
	box-shadow: 2px 2px 5px 4px #b0c2d2;
	border-radius: 15px;
}
@media screen and (max-width: 500px) {
	.search-field {
		padding: 0%;
	}
	.primary-blog-img-div {
		width: 92%;
		padding-right: 10px;
	}
	.primary-blog-img-div img {
		border-radius: 13px;
	}

	.primary-blog {
		width: 80%;
		padding-left: 10px;
	}
	.primary-blog-content-div {
		width: 100%;
		border-radius: 13px;
	}

	.primary-blog-heading {
		font-size: 5vw;
	}
	.main-para {
		font-size: 3.5vw;
	}
	.author-blog {
		font-size: 3vw;
	}
	.all-blogs {
		width: 80% !important;
		align-items: "center";
	}
	.secondary-heading {
		font-size: 3vw;
	}
	.para {
		// font-size: 1.1rem;
		font-size: 3.5vw;
	}
	.secondary-img {
		width: 70vw;
	}
	// .all-blogs {
	//   // width: 10%;
	//   align-items: center;
	// }

	.secondary-heading {
		font-size: 5vw;
	}
	.inner-blogs-name {
		padding-top: 5%;
		font-size: 2.62vw;
		padding-left: 5%;
		margin-bottom: 5%;
	}
	.main-content-image {
		margin-top: 3%;
	}
}

.paginationbuttons {
	width: 100%;
	height: 40px;
	list-style: none;
	display: flex;
	justify-content: center;
	margin-bottom: 15rem;
}

.paginationbuttons a {
	padding: 10px;
	margin: 8px;
	border-radius: 5px;
	border: 1px solid #00b8e6;
	color: #00b8e6;
	cursor: pointer;
}

.paginationbuttons a:hover {
	color: white;
	background-color: #00b8e6;
}

.paginationactive a {
	color: white;
	background-color: #00b8e6;
}
