.main-content {
	background-image: linear-gradient(#3a073b, #650a72);
	padding-left: 2%;
}
.content-img {
	width: 160px;
	margin: 3%;
}
.heading-content {
	padding: 3%;
	font-size: 2rem;
}
.icon {
	width: 6%;
	margin: 0.5%;
}
.facebook {
	width: 5.5%;
}
.author-icon {
	margin-top: 2%;
	margin-left: 2%;
	margin-bottom: 2%;
}
.icon {
	margin: 1%;
	margin-top: 2%;
	cursor: pointer;
}
.para-div {
	font-size: 120% !important;
	margin-top: 3%;
	margin-bottom: 3%;
	width: 60%;
}
.para-div p {
	margin-top: 0 !important;
	margin-bottom: 1rem !important;
	font-weight: 450 !important;
	font-size: 25px !important;
}
.animation {
	margin-bottom: 10%;
}
.main-content-image {
	/* width: 850px; */
	width: 70%;
	object-fit: cover;
	border-radius: 8px;
}
/* .para-div {
} */
.other-image {
	width: 700px;
	border-radius: 8px;
	margin-left: -8px;
}

@media screen and (max-width: 1200px) {
	.main-content-image {
		width: 96%;
	}
	.content-img {
		width: 110px;
	}

	.icon {
		width: 8%;
		margin: 2%;
	}
	.heading-content {
		font-size: 1.5rem;
	}
}
@media screen and (max-width: 780px) {
	.animation {
		width: 80%;
	}
	.para-div {
		width: 75%;
		font-size: 1.1rem;
	}
	.icon {
		width: 15%;
		margin: 3%;
	}
	.heading-content {
		font-size: 1.47rem;
	}
	.content-img {
		width: 90px;
	}
	.main-content-image {
		width: 97%;
	}
	.other-image {
		width: 97%;
	}
	.author-name {
		font-size: 15px;
	}
}
