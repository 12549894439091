* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

button {
  outline-width: 0px;
  border: none;
}

a:hover {
  color: transparent;
  text-decoration: none !important;
}

/*  */
.containerHH {
  font-size: small;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .containerHH {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}

.containerHH > span > span {
  font-size: small;
  padding-right: 5px;
}
