.homepage__feedback {
  background: #eceff4;
}
.homepage__feedback__header {
  text-align: center;
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;
  padding: 50px 10%;
  @media screen and (max-width: 990px) {
    font-size: 20px;
    line-height: 20px;
    padding: 50px 5%;
  }
}
.homepage__feedback__header > span {
  color: #00b2d9;
}
.homepage__feedback__cards__wrapper::-webkit-scrollbar {
  display: none;
}


.homepage__feedback__cards__wrapper {
  overflow-x: scroll;
  scrollbar-width: none;
  p{
    font-size: 0.8rem;
    font-weight: 500;
  }
  .homepage__feedback__cards__container {
    display: flex;
    width: 1100px!important;
    margin: 0 auto;
    @media all  and (max-width:780px) {
        margin-left: 30px;
    }
    .slot1 {
      width: 65%;
      > div {
        display: flex;
        > div {
          border-radius: 10px;
          font-weight: 400;
        }
      }
      .row1 {
        > div {
          color: white;
          font-size: 25px;
          line-height: 30px;
          margin: 20px 20px 10px 20px;
          padding: 20px;
          margin-left: 0px;
          font-weight: 600;
        }
        .card1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #7209b7;
          img {
            width: 70px;
            height: 70px;
          }
        }
        .card2 {
          background: #f72585;
        }
      }
      .row2 {
        display: flex;
        justify-content: space-between;
        > div {
          margin: 10px 20px;
          margin-left: 0px;
        }
        .card1 {
          width: 450px !important;
          font-size: 12px;
          display: flex;
          flex-direction: column;
        }
        .card2 {
          background: #4361ee;
          color: white;
          line-height: 30px;
          font-weight: 600;
          padding: 20px;
          width: 370px;
          p{
            font-size: 1.2rem;
            margin-bottom: 1rem;
          }
          div {
            text-align: right;
           
            img {
              width: 100px;
              height: 100px;
            }
          }
        }
      }
      .row3 {
        > div {
          margin: 10px 20px 10px 10px;
          margin-left: 0px;
          font-size: 13px;
          > span {
            display: block;
            height: 190px;
          }
        }
        .card1 {
          width: 310px !important;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          
          .author {
            position: relative;
            min-width: 150px;
            left: -70px;
            img {
              margin-right: 0px;
            }
          }
        }
        .card2 {
          width: 430px !important;
        }
      }
    }
    .slot2 {
      width: 35%;
    
      > div {
        margin: 20px 20px 20px 0px;
        border-radius: 10px;
        padding: 20px;
        // width: 320px !important;
      }
      .card1 {
        width: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 5px;
        // margin: 0px;
      }
      .card2 {
        background: #8ac926;
        color: white;
        font-size: 20px;
        font-weight: 500;
        width: 360px;

        span {
          font-size: 30px;
          font-weight: bold;
        }
      }
      .card3 {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        background: #d7263d;
        color: white;
        padding: 0px 20px;
        width: 360px;

        img {
          width: 150px;
          height: 150px;
          margin: 0px;
        }
      }
      .card4 {
        background: #f28f00;
        font-weight: bold;
        font-size: 30px;
        color: white;
        margin-bottom: 0px;
        width: 360px;
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          div {
            font-size: 20px;
            font-weight: normal;
            img {
              width: 70px;
              height: 70px;
            }
          }
        }
      }
    }
    .google__card {
      background: white;
      padding: 20px;
      padding-bottom: 5px;
      font-size: 12px;
      font-weight: 400;
      .google__card__fotter {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        > div:nth-child(1) {
          min-width: 150px;
        }
        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
        .google__icon {
          width: 40px;
          height: 40px;
        }
        .country__icon {
          width: 30px;
          height: 20px;
        }
        .author {
          font-size: 13px;
          font-weight: bold;
          text-align: right;
          margin-top: 20px;
          margin-left: 20px;
          div {
            font-weight: normal;
          }
        }
      }
    }
  }
}
