.aboutus__page {
  color: #000000;
  overflow: hidden;
  height: fit-content;
  .aboutus__background__img {
    background-color: #d7eefd;
    background-image: url("../Assets/about_us/background.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 70%;
    background-position: center;
    @media only screen and (max-width: 425px) {
      padding-top: 150%;
      background-size: 200% 100%;
    }
}

  .techokids__description {
    background-color: #d7eefd;
    flex-direction: column;
    align-items: center;
    display: flex;
    padding: 0 15% 15% 15%;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    width: fit-content;
    height: fit-content;
    text-align: center;

    .circular-bars{
      position: absolute;
      top: -280px;
      right: -120px;
   
      @media all and (max-width: 950px) {
        display: none;
       }
    }
    .left-half-circle{
      position: absolute;
      left: 0;
      @media all and (max-width: 950px) {
        display: none;
       }
    }
  
    .welcome{
      height: fit-content;
      width: 100%;
      padding: 10px;
      margin: auto;
      .about__separator{
        margin-bottom: 20px;
        display: block;
      }
      h1{
        font-weight: 700;
        margin-bottom: 3rem;
      }
      h3,h4,h5,h6,p{
        font-size: 1.2rem;
        display: inline;
        margin: 0;
        font-weight: 500;
        @media all and (max-width: 450px) {
         font-size: 0.8rem;
        }
      }

      @media screen and (max-width:1215px){
        margin-bottom: 120px;
      }
      @media screen and (max-width:800px){
        margin-bottom: 110px;
      }
    
      @media screen and (max-width:600px){
        margin-bottom: 110px;
      }
    
      @media screen and (max-width:400px){
        margin-bottom: 80px;
      }
      @media screen and (max-width:350px){
        margin-bottom: 100px;
      }
    }
    h5 {
      font-size: 2.5rem;
      font-weight: bold;
      margin: 0 0 40px 0;
      text-align: center;

      @media only screen and (max-width: 425px) {
        font-size: 1.5rem;
        margin-top: -3%;
        margin-bottom: 5px;
      }
    }


    @media only screen and (max-width: 720px) {
      padding-bottom: 15%;
      padding-left: 12%;
      padding-right: 12%;
    }
    @media only screen and (max-width: 550px) {
      padding: 5%;
      padding-bottom: 20%;
    }
    @media only screen and (max-width: 425px) {
      padding-bottom: 65%;
    }

    p {
      text-align: center;
      margin: 0;
      padding: 10px 0;
      font-weight: 550;
      @media only screen and (max-width: 425px) {
        font-size: 14.5px;
      }
      @media only screen and (max-width: 380px) {
        font-size: 13px;
      }
    }

    .our__belief {
      margin-top: 200px;
      display: flex;
      z-index: 1;
      flex-direction: column;
      align-items: center;
      padding: 1% 5%;
      background-color: #fff;
      color: #000000;
      position: absolute;
      box-shadow: 2px 2px 20px 4px #b0c2cd;
      // change
      bottom: -50%;
      border-radius: 70px;
      margin: 0 7%;
      font-weight: 500;

      @media only screen and (max-width: 1030px) {
      bottom: -55%;
          
      }
      @media only screen and (max-width: 990px) {
      bottom: -60%;
          
      }
      @media only screen and (max-width: 800px) {
          width: 80%;
          margin: auto;
      }
      @media only screen and (max-width: 425px) {
        padding: 0 15px;
        // width: 95%;
        margin: auto;
        border-radius: 40px;
        bottom: -40%;
      }

      .title {
        font-size: 2.4rem;
        margin: 40px 0;
        @media only screen and (max-width: 750px) {
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 555px) {
          font-size: 1.9rem;
          margin: 15px 0;
        }
      }

      p {
        text-align: center;
        margin-bottom: 8px;
        font-weight: 450;

        @media only screen and (max-width: 425px) {
          margin-bottom: 15px;
        }
      }
      .desc{
        text-align: left;
      }

      .misson__vision {
        display: flex;
        justify-content: space-around;
        @media only screen and (max-width: 780px) {
          flex-wrap: wrap;
          align-items: center;
        }
        @media only screen and (max-width: 425px) {
          flex-wrap: wrap;
          align-items: center;
          
        }

        .mission__section,
        .vision__section {
          display: flex;
          width: 500px;
          margin-top: 20px;
          // change
          height: 200px;
          @media only screen and (max-width: 1280px) {
           height: 200px;  


          }
          @media only screen and (max-width: 780px) {
            width: 300px;

          }
          @media only screen and (max-width: 425px) {
            padding: 4%;
          }
         

          img {
            height: 55%;
            @media only screen and (max-width: 780px) {
              width: 30%;
            }
            
            @media only screen and (max-width: 425px) {
              width: 30%;
            }
          }
          @media screen and (max-width:450px){
            width:100%;
            height: fit-content;
          }
        }

        .mission__description,
        .vision__description {
          display: flex;
          flex-direction: column;
          text-align: left;
          .header {
            font-size: 2.2rem;
            margin: 0;
            @media only screen and (max-width: 780px) {
              font-size: 1.9rem;
            }
            @media only screen and (max-width: 425px) {
              font-size: 1.3rem;
            }
          }

        }
      }
    }
  }

  .our__team {
    $padding_our_team:50%;
    background-color: #e5e5e5;
    background-image: url("../Assets/about_us/background_recharge.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: $padding_our_team ;
    position: relative;
    
   @media only screen and (max-width: 1250px) {
      padding-top: $padding_our_team + 12
    }
 
    
   @media only screen and (max-width: 1150px) {
      padding-top: $padding_our_team + 40
    }
 
   @media only screen and (max-width: 1050px) {
      padding-top: $padding_our_team + 50
    }
 
    @media only screen and (max-width: 950px) {
      padding-top: $padding_our_team + 60

    }
    @media only screen and (max-width: 840px) {
      padding-top: $padding_our_team + 80

    }
    @media only screen and (max-width: 740px) {
      padding-top: $padding_our_team + 110

    }
    @media only screen and (max-width: 640px) {
      padding-top: $padding_our_team + 150

    }
    @media only screen and (max-width: 570px) {
      padding-top: $padding_our_team + 170

    }
    @media only screen and (max-width: 500px) {
      padding-top: $padding_our_team + 180

    }
    @media only screen and (max-width: 425px) {
      padding-top: $padding_our_team + 150

    }
    @media only screen and (max-width: 350px) {
      padding-top: $padding_our_team + 170

    }

    .slogan {
      position: absolute;
      top: 65%;
      width: 100%;
      text-align: center;
    
      .our__team-battery {
        margin: 0;
        text-align: center;
        font-weight: 600;
       color: #000000 ;
        
        @media only screen and (max-width: 425px) {
          font-size: 1rem;
        }
      }

      .our__team-recharge {
        font-weight: 750;
        margin: 0;
        text-align: center;
        padding-top: 5px;
        color: #000000 ;

        @media only screen and (max-width: 425px) {
          font-size: 1.4rem;
        }
      }
    }

    .robot {
      position: absolute;
      bottom: -3%;
      right: 7%;
      height: 36%;

      @media only screen and (max-width: 960px) {
        height: 350px;
        right: 2%;
      }
      @media only screen and (max-width: 425px) {
        bottom: -2%;
        right: 5%;
        height: 31%;
      }
    }

    .battery {
      position: absolute;
      top: 79.5%;
      left: 8%;
      z-index: 1;
      height: 240%;
      width: 100%;

      @media only screen and (max-width: 1000px) {
        width: 200%;
        left: -50%;
        bottom: 0;
      }
      @media only screen and (max-width: 900px) {
        width: 250%;
        left: -90%;
        bottom: 0;
      }
   
    }
  }

  .team_members {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    justify-content: space-evenly;
    padding-top: 20%;
    padding-bottom: 10%;
    @media only screen and (max-width: 425px) {
      margin: 10px;
    }

    .left__section,
    .right__section {
      color: #fff;
      flex-wrap: wrap;
      font-size: 18px;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 550px) {
        width: 90%;
      
      }

      .memeber__profile {
        z-index: 1;
        position: relative;

      
        @media only screen and (max-width: 550px) {
          font-size: 14px;
          width: 100%;
          display: flex;
          justify-content: center;

          img {
            width: 100%;
          }
      
        }

        .profile__pic {
          position: absolute;
          top: -8%;
          left: 0;
          height: 50%;
        
          @media only screen and (max-width: 550px) {
            width: 30%;
            height: auto;    
          }
          @media only screen and (max-width: 450px) {
            width: 30%;
            height: auto;    
            top: 5px;

          }
        }
      }

      .title {
        position: absolute;
        top: 22%;
        left: 36%;
        @media only screen and (max-width: 550px) {
       left: 32%;
        }
        h5 {
          font-size: 20px;
          margin: 0px;
          line-height: 20px;

          @media only screen and (max-width: 550px) {
            font-size: 1rem;
            line-height: 1rem;
          

          }
        }
        div{
          margin: 0px;
          font-size: 17px;
          line-height: 17px;
          @media only screen and (max-width: 550px) {
            font-size: 12px;
            line-height: 12px;
          }
        }
        img{
          width: 200px;
          position: relative;
          left: -10px;          
        }
      }

      .description {
        left: 8%;
        position: absolute;
        top: 31%;
        padding: 10% 8%;
        p{
          font-size: 18px;
          line-height: 19px;
          @media only screen and (max-width: 550px) {
            font-size: 12.5px;
            font-weight: 600;
          }
        }
      }
    }

    .right__section {
      padding-top: 10%;
    }

    .connector__left,
    .connector__right {
      position: absolute;
      top: -8%;

      @media only screen and (max-width: 425px) {
        width: 60%;
      }
    }

    .connector__left {
      left: -40%;
      top: -180px;

      @media only screen and (max-width: 425px) {
        left: -28%;
        top: -4.7%;
      }
    }

    .connector__right {
      top: -180px;
      right: -30%;

      @media only screen and (max-width: 425px) {
        top: -4.8%;
        right: -2%;
      }
    }
  }
}

  .lightening__source{
    background: #edf0f4;
    text-align: center;
    padding-bottom: 280px;
    margin-top: -160px;
    @media only screen and (max-width: 425px) {
      margin-top: 50px;
      padding-top: 50px;
    }
    h2{
      font-size: 40px;
      margin: 3rem;
      font-weight: 600;
      @media only screen and (max-width: 550px) {
        margin: 3rem 1rem;
      }
    }
    p{
      margin: 15px 10%;
      font-size: 1.2rem !important;
      @media only screen and (max-width: 550px) {
        font-size: 0.8rem !important;
        margin: 15px 5%;
      }
    }
    .lightening__source__grid{
      display: flex;
      flex-wrap: wrap;
      margin: 0px 10%;
      margin-top: 50px;
      @media only screen and (max-width: 425px) {
        margin: 0px 2%;
      }
      div{
        width: 30%;
        padding: 5px 0px;
        margin: 10px auto;
        img{
          width: 200px;
          height: 200px;
          border-radius: 50%;
          @media only screen and (max-width: 425px) {
            width: 100px;
          }
        }
      }
    }
    .lightening__source__card, .lightening__source__external__card{
      img {
        @media screen and (max-width: 990px){
          width: 150px !important;
          height: 150px !important;
        }
        @media screen and (max-width: 550px){
          width: 100px !important;
          height: 100px !important;
        }
      }
    }
    .lightening__source__external__card{
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      div{
        margin: 10px 50px;
        img{
          width: 200px;
          height: 200px;
          border-radius: 50%;
        }
      }
    }
    .ls{
      @media only screen and (max-width: 425px) {
        display: none;
      }
    }
    .ls1{
      position: relative;
      left: -15%;
      top: 100px;
    }
    .ls3{
      position: relative;
      right: -65%;
      top: 400px;
    }
    .ls4{
      position: relative;
      left: -40%;
      top: 500px;
      @media only screen and (max-width: 850px) {
        display: none;
      }

    }
    .ls5{
      position: relative;
      top: 700px;
      right: -30%;
    }
    .ls6{
      position: relative;
      top: 870px;
      left: -70%;
    }
    .ls7{
      position: relative;
      right: -10%;
      top: 1080px;
    }
  }

  .aboutus__page p{
    font-size: 1rem;

    @media all and (max-width: 450px) {
     font-size: 0.8rem;
    }
   

  }